import work1 from '../../assets/web1.png'
import work2 from '../../assets/Blackjack.png'
import work3 from '../../assets/others1.png'
import work4 from '../../assets/Block.png'
import work5 from '../../assets/UI.png'
import work6 from '../../assets/snake.jpeg'

export const projectsData = [
    {
      id: 1,
      image: work1,
      title: "Weather Now",
      category: "web",
      url: "https://weathernow.bernardgeorgecharles.tech/",
    },
    {
      id: 2,
      image: work2,
      title: "Blackjack Game",
      category: "Java",
      url: "https://github.com/MeGaTroNOO7/BlackJack",
    },
    {
      id: 3,
      image: work3,
      title: "Ping Pong Game",
      category: "Java",
      url: "https://github.com/MeGaTroNOO7/PingPongGame",
    },
    {
      id: 4,
      image: work4,
      title: "Queblock",
      category: "web",
      url: "https://github.com/MeGaTroNOO7/Queblock",
    },
    {
      id: 5,
      image: work5,
      title: "Fin-Org",
      category: "design",
      url: "https://github.com/MeGaTroNOO7/Fin-Org",
    },
    {
      id: 6,
      image: work6,
      title: "Snake Game",
      category: "Java",
      url: "https://github.com/MeGaTroNOO7/Snake",
    },
  ];
  
export const projectsNav = [
    {
        name: 'all'
    },
    {
        name: 'web'
    },
    {
        name: 'design'
    },
    {
        name: 'Java'
    }
]