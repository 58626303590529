import React from "react";
import "./project.css";
import Projects from "./Projects";
import { Helmet } from "react-helmet";

const Project = () => {
  return (
    <>
      <Helmet>
        <title>Bernard George Charles</title>
        <meta
          name="keywords"
          content="BernardGeorgeCharles, Bernard, George, Bernard_George_Charles_, Bernard_George_Charles"
        />
        <meta
          name="description"
          content="Full-time geek exploring existence and building his own legacy. Join with me down below and let's get cracking!"
        />
        <meta name="subject" content="Student and Developer" />
        <meta name="copyright" content="BernardGeorgeCharles" />
        <meta name="robots" content="max-snippet:-1, max-image-preview:large" />
        <meta name="topic" content="Bernard's portfolio" />
        <meta name="summary" content="Student and Developer" />
        <meta name="Classification" content="Business" />
        <meta name="author" content="BernardGeorgeCharles, Bernardscreed@gmail.com" />
        <meta name="reply-to" content="BernardGeorgeCharles67@gmail.com" />
        <meta name="owner" content="BernardGeorgeCharles" />
        <meta name="url" content="https://www.George.engineer/" />
        <meta name="identifier-URL" content="https://www.George.engineer/" />
        <meta name="category" content="portfolio" />
        <meta name="coverage" content="Worldwide" />
        <meta name="distribution" content="Global" />
        <meta name="rating" content="General" />
        <meta name="og:title" content="Bernard George Charles" />
        <meta name="og:type" content="portfolio" />
        <meta name="og:url" content="https://www.George.engineer/" />
        <meta
          name="og:image"
          content="https://raw.githubusercontent.com/BernardGeorgeCharles/portfolio/main/src/assets/aboutRED.jpg"
        />
        <meta name="og:site_name" content="BernardGeorgeCharles" />
        <meta
          name="og:description"
          content="Full-time geek exploring existence and building his own legacy. Join with me down below and let's get cracking!"
        />
        <meta name="og:email" content="BernardGeorgeCharles67@gmail.com" />
      </Helmet>
      <section className="work section" id="projects">
        <h2 className="section__title">Projects</h2>
        <span className="section__subtitle">Most recent works</span>
        <Projects />
      </section>
    </>
  );
};

export default Project;
