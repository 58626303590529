import React from 'react'

function Social() {
  return (
    <div className="home__social">
        <a href="https://github.com/MeGaTroNOO7" className="home__social-icon"><i className="uil uil-github-alt"></i></a>
        <a href="https://www.instagram.com/bernard_george_charles/" className="home__social-icon"><i className="uil uil-instagram"></i></a>
        <a href="https://www.codechef.com/users/bernard_007" className="home__social-icon"><i class="uil uil-arrow"></i></a>
    </div>
  )
}

export default Social