import React, { useState } from "react";
import "./qualification.css";
import { Helmet } from "react-helmet";

const Qualification = () => {
  const [toggleState, setToggleState] = useState(1);
  const toggleTab = (index) => {
    setToggleState(index);
  };
  return (
    <>
      <Helmet>
        <title>Bernard George Charles</title>
        <meta
          name="keywords"
          content="BernardGeorgeCharles, Bernard, George, Bernard_George_Charles_, Bernard_George_Charles"
        />
        <meta
          name="description"
          content="Full-time geek exploring existence and building his own legacy. Join with me down below and let's get cracking!"
        />
        <meta name="subject" content="Student and Developer" />
        <meta name="copyright" content="BernardGeorgeCharles" />
        <meta name="robots" content="max-snippet:-1, max-image-preview:large" />
        <meta name="topic" content="Bernard's portfolio" />
        <meta name="summary" content="Student and Developer" />
        <meta name="Classification" content="Business" />
        <meta name="author" content="BernardGeorgeCharles, Bernardscreed@gmail.com" />
        <meta name="reply-to" content="BernardGeorgeCharles67@gmail.com" />
        <meta name="owner" content="BernardGeorgeCharles" />
        <meta name="url" content="https://www.George.engineer/" />
        <meta name="identifier-URL" content="https://www.George.engineer/" />
        <meta name="category" content="portfolio" />
        <meta name="coverage" content="Worldwide" />
        <meta name="distribution" content="Global" />
        <meta name="rating" content="General" />
        <meta name="og:title" content="Bernard George Charles" />
        <meta name="og:type" content="portfolio" />
        <meta name="og:url" content="https://www.George.engineer/" />
        <meta
          name="og:image"
          content="https://raw.githubusercontent.com/BernardGeorgeCharles/portfolio/main/src/assets/aboutRED.jpg"
        />
        <meta name="og:site_name" content="BernardGeorgeCharles" />
        <meta
          name="og:description"
          content="Full-time geek exploring existence and building his own legacy. Join with me down below and let's get cracking!"
        />
        <meta name="og:email" content="BernardGeorgeCharles67@gmail.com" />
      </Helmet>
      <section className="qualification section">
        <h2 className="section__title">Qualification</h2>
        <span className="section__subtitle">My personal journey</span>
        <div className="qualification__container container">
          <div className="qualification__tabs">
            <div
              className={
                toggleState === 1
                  ? "qualification__button qualification__active button--flex"
                  : "qualification__button button--flex"
              }
              onClick={() => toggleTab(1)}
            >
              <i className="uil uil-graduation-cap qualification__icon"></i>{" "}
              Education
            </div>
            <div
              className={
                toggleState === 2
                  ? "qualification__button qualification__active button--flex"
                  : "qualification__button button--flex"
              }
              onClick={() => toggleTab(2)}
            >
              <i className="uil uil-briefcase-alt qualification__icon"></i>{" "}
              Experience
            </div>
          </div>
          <div className="qualification__sections">
            <div
              className={
                toggleState === 1
                  ? "qualification__content qualification__content-active"
                  : "qualification__content"
              }
            >
              <div className="qualification__data">
                <div>
                  <h3 className="qualification__title">College</h3>
                  <span className="qualification__subtitle">CSE - Panimalar Institute of Technology</span>
                  <div className="qualification__calendar">
                    <i className="uil uil-calendar-alt"></i> 2020 - Present
                  </div>
                </div>
                <div>
                  <span className="qualification__rounder"></span>
                  <span className="qualification__line"></span>
                </div>
              </div>
              <div className="qualification__data">
                <div></div>
                <div>
                  <span className="qualification__rounder"></span>
                  <span className="qualification__line"></span>
                </div>
                <div>
                  <h3 className="qualification__title">Higher Secondary</h3>
                  <span className="qualification__subtitle">
                    CSE - TI School
                  </span>
                  <div className="qualification__calendar">
                    <i className="uil uil-calendar-alt"></i> 2018 - 2020
                  </div>
                </div>
              </div>
              <div className="qualification__data">
                <div>
                  <h3 className="qualification__title">Secondary Education</h3>
                  <span className="qualification__subtitle">
                    grade 10 - St. Joseph's Matriculation School
                  </span>
                  <div className="qualification__calendar">
                    <i className="uil uil-calendar-alt"></i> 2005 - 2018
                  </div>
                </div>
                <div>
                  <span className="qualification__rounder"></span>
                  <span className="qualification__line"></span>
                </div>
              </div>
            </div>
            <div
              className={
                toggleState === 2
                  ? "qualification__content qualification__content-active"
                  : "qualification__content"
              }
            >
              <div className="qualification__data">
                <div>
                  <h3 className="qualification__title">Representative</h3>
                  <span className="qualification__subtitle">Volleyball Team Captain</span>
                  <div className="qualification__calendar">
                    <i className="uil uil-calendar-alt"></i> 2018 - 2020
                  </div>
                </div>
                <div>
                  <span className="qualification__rounder"></span>
                  <span className="qualification__line"></span>
                </div>
              </div>
              <div className="qualification__data">
                <div></div>
                <div>
                  <span className="qualification__rounder"></span>
                  <span className="qualification__line"></span>
                </div>
                <div>
                  <h3 className="qualification__title">Intern</h3>
                  <span className="qualification__subtitle">
                    Insicle
                  </span>
                  <div className="qualification__calendar">
                    <i className="uil uil-calendar-alt"></i> 2022 - 2023
                  </div>
                </div>
              </div>
              <div className="qualification__data">
                <div>
                  <h3 className="qualification__title">Visual Processing Topper</h3>
                  <span className="qualification__subtitle">
                    Super Brain Challenge
                  </span>
                  <div className="qualification__calendar">
                    <i className="uil uil-calendar-alt"></i> 2021 - 2022
                  </div>
                </div>
                <div>
                  <span className="qualification__rounder"></span>
                  <span className="qualification__line"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Qualification;
