import React from "react";
import "./footer.css";
import { Helmet } from "react-helmet";

const Footer = () => {
  return (
    <>
      <Helmet>
        <title>Bernard George Charles</title>
        <meta
          name="keywords"
          content="BernardGeorgeCharles, Bernard, George, Bernard_George_Charles_, Bernard_George_Charles"
        />
        <meta
          name="description"
          content="Full-time geek exploring existence and building his own legacy. Join with me down below and let's get cracking!"
        />
        <meta name="subject" content="Student and Developer" />
        <meta name="copyright" content="BernardGeorgeCharles" />
        <meta name="robots" content="max-snippet:-1, max-image-preview:large" />
        <meta name="topic" content="Bernard's portfolio" />
        <meta name="summary" content="Student and Developer" />
        <meta name="Classification" content="Business" />
        <meta name="author" content="BernardGeorgeCharles, Bernardscreed@gmail.com" />
        <meta name="reply-to" content="BernardGeorgeCharles67@gmail.com" />
        <meta name="owner" content="BernardGeorgeCharles" />
        <meta name="url" content="https://www.George.engineer/" />
        <meta name="identifier-URL" content="https://www.George.engineer/" />
        <meta name="category" content="portfolio" />
        <meta name="coverage" content="Worldwide" />
        <meta name="distribution" content="Global" />
        <meta name="rating" content="General" />
        <meta name="og:title" content="Bernard George Charles" />
        <meta name="og:type" content="portfolio" />
        <meta name="og:url" content="https://www.George.engineer/" />
        <meta
          name="og:image"
          content="https://raw.githubusercontent.com/BernardGeorgeCharles/portfolio/main/src/assets/aboutRED.jpg"
        />
        <meta name="og:site_name" content="BernardGeorgeCharles" />
        <meta
          name="og:description"
          content="Full-time geek exploring existence and building his own legacy. Join with me down below and let's get cracking!"
        />
        <meta name="og:email" content="bernardgeorgecharles007@gmail.com" />
      </Helmet>
      <footer className="footer">
        <div className="footer__container container">
          <h1 className="footer__title">バーナード</h1>
          <ul className="footer__list">
            <li>
              <a href="#about" className="footer__link">
                About
              </a>
            </li>
            <li>
              <a href="#projects" className="footer__link">
                Projects
              </a>
            </li>
            <li>
              <a
                href="https://megatronoo7.github.io/socials/"
                className="footer__link"
              >
                Socials
              </a>
            </li>
            <li>
              <a href="#contact" className="footer__link">
                Contact
              </a>
            </li>
          </ul>
          <div className="footer__social">
            <a
              href="https://github.com/MeGaTroNOO7"
              className="footer__social-link"
            >
              <i className="bx bxl-github"></i>
            </a>
            
            <a
              href="https://www.instagram.com/bernard_george_charles/"
              className="footer__social-link"
            >
              <i className="bx bxl-instagram"></i>
            </a>
            
          </div>
          <span className="footer__copy">
            &#169; BernardGeorgeCharles. All rights reserved
          </span>
        </div>
      </footer>
    </>
  );
};

export default Footer;
